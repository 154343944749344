<template>
  <div class="home">
    <Hero />
    <Services />
    <Experience />
    <Professional />
    <Publications />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import Services from '../components/Services.vue'
import Experience from '../components/Experience.vue'
import Professional from '../components/Professional.vue'
import Publications from '../components/Publications.vue'
import Contact from '../components/Contact.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  props: {},
  components: {
    Hero,
    Services,
    Experience,
    Professional,
    Publications,
    Contact,
    Footer
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:900&display=swap');
</style>
