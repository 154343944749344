<template>
    <section class="publications">
      <div class="content">
        <h2>Recent presentations &amp; publications</h2>
        <ul>
            <li>Co-presenter, "The Competence-Competence Principle for Litigators" , Canadian Bar Association, Civil Litigation (South), webinar, January 28, 2025</li>
            <li>Panelist, "Costs", Western Canada Commercial Arbitration Society, Calgary, May 7, 2024</li>
            <li>Co-Author, “Case Comment – Campbell v. The Bloom Group, 2023 BCCA 84”, New York Dispute Resolution Lawyer, 2024, Vol. 17 No. 1,  p, 35, March 12, 2024</li>
            <li>Co-Facilitator, “Fairness in Team Selection/Carding Policies: Have We Made Any Progress in 20 Years?”, SDRCC Mediator and Arbitrator Conference, Calgary, February 1, 2024</li>
            <li>Panelist, “Arbitration Advocacy”, Advocates’ Society, Calgary, November 22, 2023</li>
            <li>Presenter, “Ask an Arbitrator: What to Consider When Advising Your Clients Whether to Arbitrate”, Canadian Bar Association, Civil Litigation (South) Section – Webinar, Calgary, March 28, 2023</li>
            <li>Panelist, “Becoming an Arbitrator”, Association of Women Lawyers (Calgary), Canmore, January 28, 2023</li>
            <li>Co-presenter, “Construction Arbitrations: Hot topics in construction arbitration, including an update on case law", Canadian Bar Association, Construction Law Section (South), January 16, 2023”</li>
            <li>Presenter, “Costs in a Domestic Commercial Arbitration” Canadian Bar Association, Alternative Dispute Resolution Section – South Webinar, Calgary, May 4, 2022</li>
            <li>Co-presenter, “Case Law Update”, Western Canada Commercial Arbitration Society Conference, Calgary, May 3, 2022</li>
            <li>Presenter, “Considerations and Tips for Arbitration Award Writing”, Justice Marie L. Garibaldi ADR Inn of Court, November 11, 2021</li>
            <li>Panelist, “Best Practices for Construction Arbitrations I: Practical Tools”, Alberta Construction Law Advocacy Program, Advocates’ Society, October 20, 2021</li>
            <li>Panelist, “Domain Name Disputes and ADR”, Intellectual Property Institute of Canada, online, July 27, 2021</li>
            <li>Panelist, “Construction Dispute Symposium Series – Arbitration” Royal Institute of Chartered Surveyors and the Chartered Institute of Arbitrators, Canada Branch, online, June 22, 2021</li>
            <li>Panelist, “The Litigator’s Seminar: Updates and Tools for Making Your Practice Better – Virtual Hearings”, the Law Society of Saskatchewan, online, June 10, 2021</li>
            <li>Co-presenter, “Legal Update”, Western Canada Commercial Arbitration Society Conference, Calgary, May 4, 2021</li>
            <li>Author, “The Courts’ Decisions Concerning Their Own Virtual Hearings: <a v-bind:href="'https://adric.ca/adr-perspectives/the-courts-decisions-concerning-their-own-virtual-hearings-what-is-good-for-the-goose/'">‘What is Good for the Goose…’</a>” , ADR Perspectives, Vol.8 No.1 March 2020/mars 2020, online</li>
            <li>Co-Author, <a v-bind:href="'https://urbas.ca/wp-content/uploads/2020/11/Virtual-Practice-Makes-Virtually-Perfect-%E2%80%93-Julie-G.-Hopkins-and-Daniel-Urbas-November-27-2020-2.pdf'">&ldquo;Virtual Practice Makes Virtually Perfect &ndash; Practical Considerations for Virtual Hearings Identified through Simulations with Experienced Counsel and Arbitrators&rdquo;</a> (27 November 2020), online</li>
            <li>
            Panelist "Doing It Right: Ethics for Domestic Commercial Arbitration", Chartered Institute of Arbitrators 8th Annual Symposium on International and Domestic Arbitration, September 24, 2020
            </li>
            <li>Co-Author, <a v-bind:href="'https://www.thelawyersdaily.ca/articles/19692'">&ldquo;Alberta and Ontario courts warm to videoconferencing&rdquo;</a> The Lawyers Daily, June 23, 2020, online</li>
            <li>Author, <a v-bind:href="'https://adric.ca/adr-perspectives/the-test-for-permission-for-a-second-level-of-appeal/'">&ldquo;The Test for Permission for a Second Level of Appeal&rdquo;</a> ADR Perspectives, Vol.7 No.2 May 2020/mai 2020, online</li>
        </ul>
      </div>
    </section>
</template>

<script>
export default {
  name: 'Publications',
  props: {}
}
</script>

<style lang="scss" scoped>
section.publications {
  background: $light-grey;
  padding-top: 0;
  .content {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 768px) {
  section.publications {
    .content {
      max-width: 50vw;
    }
  }
}
</style>
