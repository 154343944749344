<template>
  <section class="services">
    <h2>Services</h2>
    <p class="intro">I am an arbitrator and mediator based in Calgary, Alberta with more than 30 years of experience in dispute resolution.</p>
    <div>
      <dl>
        <img src="../assets/services01.png" alt="Office buildings of downtown Calgary" />
        <dt>Commercial Arbitrator</dt>
        <dd>I am a member of Western Arbitration Chambers and arbitrate domestic and international commercial disputes.</dd>
      </dl>
      <dl>
        <img src="../assets/services02.png" alt="Wonderland statue in front of The Bow office tower" />
        <dt>Labour Arbitrator</dt>
        <dd>As a labour adjudicator and arbitrator, my focus is on a fair and efficient process and rendering clear and timely decisions.</dd>
      </dl>
      <dl>
        <img src="../assets/services03.png" alt="Interior of The Calgary Central Library" />
        <dt>Workplace Investigator</dt>
        <dd>As a workplace investigator, I bring my experience and perspective as litigation counsel and as decision-maker to all aspects of the investigative process.</dd>
      </dl>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Services',
  props: {}
}
</script>

<style lang="scss" scoped>
section.services {
  padding-bottom: 1em;
  img {
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 1.5em;
  }
  p.intro {
    font-size: 1.25em;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  section.services {
    padding-right: 12em!important;
    padding-left: 12em!important;
    div {
      display: -ms-flex;
      display: -webkit-flex;
      display: flex;
      margin-top: 0.75em;

      & > dl {
        width: calc(100%/3);
      }

      & > dl:first-child {
        margin-right: 1.5em;
      }
      
      & > dl:last-child {
        margin-left: 1.5em;
      }
    }
  }
}
</style>
