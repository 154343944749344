<template>
    <section class="contact">
        <h2>Contact</h2>
        <address>
            <p><strong>Julie G. Hopkins</strong><br>10th Floor &ndash; 888 3rd Street, SW, Banker's Hall, West Tower<br>Calgary, AB, T2P 5C5</p>
            <p>e: <a href="mailto:Julie.Hopkins@jghopkins.com" class="no-icon">Julie.Hopkins@jghopkins.com</a><br>
            p: <a href="tel:+4038351347" class="no-icon">(403) 835-1357</a></p>
        </address>
    </section>
</template>

<script>
export default {
  name: 'Contact',
  props: {}
}
</script>

<style lang="scss" scoped>
section.contact {
    background: url('../assets/contact.png') 50% 50% no-repeat;
    background-size: cover;
    color: $white;
    address {
        font-style: normal;
    }
    a {
        color: $white;
    }
}
@media only screen and (min-width: 768px) {
  section.contact {
    padding: $gutter*8 $gutter*4;
  }
}
</style>
