<template>
  <section class="experience">
    <div>
        <img src="../assets/julie-hopkins.png" alt="Photo of Julie G. Hopkins" />
    </div>
    <div>
        <h2>Experience &amp; expertise</h2>
        <h3>About Me</h3>
        <p>Prior to practicing as an independent arbitrator and mediator, I was counsel with Borden Ladner Gervais LLP and practised commercial litigation, arbitration and administrative law for more than 25 years.</p>
        <p>I advised and represented clients on complex and technical matters concerning oil and gas, insurance, labour and employment, estates and trusts, corporate, and constitutional law. As a result, I have experience across a variety of industries including electricity generation and transmission, oil and gas (including oilsands, pipelines and LNG), construction, aviation, trucking, real-estate development, health care and cannabis. I also have experience with franchise disputes.</p>
        <p>As an arbitrator, I am experienced with multi-party disputes and have been appointed to ad hoc, institutional, domestic and international arbitrations. I am on the rosters of a number of arbitral institutions including the CPR’s Panels of Distinguished Neutrals (Global Panel), ICDR Canada, the Vancouver International Commercial Arbitration Centre (Domestic and International Panels) and the Sport Dispute Resolution Centre of Canada. In addition to sitting as an arbitrator, I am an adjudicator under the Canada Labour Code and was a Panel Chair on the Alberta Insurance Councils Appeal Board for a number of years.</p>
        <p>I am a Fellow of the Chartered Institute of Arbitrators (“CIArb”) and Chair of the CIArb’s Canada Branch.</p>
    </div>
    </section>
</template>

<script>
export default {
  name: 'Experience',
  props: {}
}
</script>

<style lang="scss" scoped>
section {
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  display: block;
  padding-top: 4em!important;
  padding-bottom: 1em!important;

  img {
    margin-bottom: 3em;
  }
}

@media only screen and (min-width: 768px) {
  section {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    padding-left: 0!important;

    & > div {
      width: 100%;
      img {
        width: 100%;
      }
      h2 {
        margin-top: 9px;
      }
    }

    & > div:first-child {
      margin-right: 3em;
    }
    
    & > div:last-child {
      padding-right: 2em;
    }
  }
}

@media only screen and (min-width: 1024px) {
  section {
    & > div:last-child {
      padding-right: 8em;
    }
  }
}
</style>
