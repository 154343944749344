<template>
  <section class="hero">
    <h2>Independent Arbitrator and Mediator</h2>
    <h1>Julie G. Hopkins</h1>
    <p>A clear, logical approach</p>
    <button v-scroll-to="'footer'" class="link">
        Contact
    </button>
  </section>
</template>

<script>
export default {
  name: 'Hero',
  props: {}
}
</script>

<style lang="scss" scoped>
section.hero {
  background: url('../assets/hero.png') 50% 50% no-repeat;
  background-size: cover;
  color: $white;
  padding-top: 4em;
  padding-bottom: 3em;
  text-align: center;
  h2 {
    font-size: 1.15em;
    margin-bottom: 0;
    &:before {
      background-color: $white;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  p {
    margin-bottom: 0.75em;
  }
  a, button.link {
    color: $white;
    text-transform: uppercase;
  }
}
@media only screen and (min-width: 768px) {
  section.hero {
    height: 30em;
    padding-top: 20em;
    padding-bottom: 2em;
    h1 {
      font-size: 3em;
    }
  }
}
</style>
