<template>
    <footer>
        <img src="../assets/wac-logo.png" alt="Western Arbitration Chambers" width="176" />
        <p>Copyright &copy; {{currentYear}} J. G. Hopkins Professional Corporation</p>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
  computed: {
    currentYear: function () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  padding-top: 2em;
  padding-bottom: 1rem;
  img {
    width: 200px;
  }
  a {
    display: block;
    margin-bottom: $gutter;
  }
  p{
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $gutter*1.5 $gutter*4;
  }
}
</style>
