<template>
  <section class="professional">
    <div class="content">
      <h2>Professional activities &amp; accomplishments</h2>
        <dl>
          <dt>Affiliations and Rosters</dt>
          <dd>
            <ul>
              <li>Member, Western Arbitration Chambers (formerly Calgary Energy and Commercial Arbitrators)</li>
              <li>Director, Vancouver International Arbitration Centre</li>
              <li>Director and Past Chair, Chartered Institute of Arbitrators, Canada Branch</li>
              <li>Member, Western Canada Commercial Arbitration Society</li>             
              <li>Panelist, CPR’s Panels of Distinguished Neutrals &ndash; Global Panel</li>
              <li>Panelist, International Centre for Dispute Resolution (ICDR) Canada Panel of Arbitrators</li>
              <li>Panelist, ICDR International Panel of Mediators</li>
              <li>Panelist, Vancouver International Commercial Arbitration Centre &ndash; Domestic and International Panels</li>
              <li>Roster Member, Sport Dispute Resolution Centre of Canada</li>
              <li>Roster Member, Edmonton Metropolitan Region Board’s Facilitator/Mediator Roster</li>
              <li>Member, Canadian Roster for Dispute Settlement Panels under Chapter 19 (Trade Remedies) of the North American Free Trade Agreement </li>
              <li>Member, Article 31.22 Advisory Committee on Private Commercial Disputes under Canada-United States-Mexico Agreement (CUSMA)</li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>Other Professional Activities</dt>
          <dd>
            <ul>
              <li>Sessional Instructor, University of Calgary, Faculty of Law, Private International Dispute Resolution</li>
              <li>Co-Coach, University of Calgary Vis Moot Team, 2021 to present</li>
              <li>Member, Editorial Board, New York Dispute Resolution Lawyer, a publication of the ADR Section of the New York State Bar Association</li>
              <li>Alberta Sessions Co-ordinator, Supreme Court Advocacy Institute (2019 – 2022)</li>
              <li>Mentor, Law Society of Alberta</li>
              <li>Contributor, <a href="https://arbitrationmatters.com/author/jhopkins_j989y77" target="_blank">Arbitration Matters</a> blog</li>
              <li>Panel Chair, Insurance Councils Appeal Board of Alberta (which hears appeals from decisions of the Insurance Councils responsible for licensing and disciplining insurance agents, adjusters and brokers) (2008 &ndash; 2016)</li>
              <li>Member, Insurance and Disaster Recovery Program Advisory Committee that provided advice to the Minister of Municipal Affairs on disaster recovery assistance and insurance issues arising from the 2013 flooding of Southern Alberta, 2014 &ndash; 2015</li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>Recognitions</dt>
          <dd>
            <ul>
              <li>Canadian Legal Lexpert® Directory for Commercial Arbitration (2024)</li>
              <li>Global Arbitration Review Legal Who's Who, Arbitrator (2024 Canada and Global)</li>
              <li>Refer to Her&trade;, Arbitrators and Mediators List</li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>Bar Admission, Education and Accreditations</dt>
          <dd>
            <ul>
              <li>Mediating the Litigated Case, Straus Institute of Dispute Resolution, Pepperdine University, 2021</li>
              <li>Fellow, Chartered Institute of Arbitrators, 2014</li>
              <li>Member, Law Society of Alberta, 1994</li>
              <li>LL.B., University of Alberta, 1993</li>
              <li>B.A.(Hons.), Political Science, University of Calgary, 1990</li>
            </ul>
          </dd>
        </dl>
      </div>
    </section>
</template>

<script>
export default {
  name: 'Professional',
  props: {}
}
</script>

<style lang="scss" scoped>
section.professional {
  background: $light-grey;
  .content {
    margin: 0 auto;
  }
}
@media only screen and (min-width: 768px) {
  section.professional {
    .content {
      max-width: 50vw;
    }
  }
}
</style>
